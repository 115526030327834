import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SidebarComponent } from './sidebar.component';
import { NavigationModule } from 'src/app/core/navigation/navigation.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PerfectScrollbarModule,
        NavigationModule
    ],
    declarations: [SidebarComponent      
    ],
    exports: [SidebarComponent]
})
export class SidebarModule { }
