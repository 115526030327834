export const NAVIGATION_ADMIN: any[] = [
  {
    title: 'Tableau de bord',
    icon: 'ft-home',
    url: '/dashboard',
  },
  {
    heading: true,
    title: 'Services'
  },
  {
    title: 'Credit d\'appel',
    icon: 'ft-phone-call',
    url: '/credit-appel',
  },
  {
    title: ' Service SMS',
    icon: 'ft-mail',
    url: '/service-sms',
    childrens: [
      {
        title: 'Groupes de contacts ',
        url: '/groupe-contact',
      },
      {
        title: 'Contacts SMS',
        url: '/contact',
      },
     
      {
        title: 'Envois SMS',
        url: '/envoyer-sms',
      },
     
    ]
  },


  {
    heading: true,
    title: 'Gestion'
  },

  {
    title: 'Transactions crédits',
    icon: 'ft-bar-chart-2',
    url: '/historique-transactions'
  },

  // {
  //   title: 'Historique activation',
  //   icon: 'ft-bar-chart-2',
  //   url: '/historique-activation'
  // },

  {
    title: 'Reporting crédits',
    icon: 'ft-mail',
    url: '/reporting'
  },

  {
    title: 'Chargements flottes',
    icon: 'ft-sidebar',
    url: '/charger-flotte',
    childrens: [
      {
        title: 'Chargement direct',
        url: '/chargement-direct',
      },
      {
        title: 'Chargement collect',
        url: '/chargement-collect',
      },
      {
        title: 'Reporting collect',
        url: '/reporting-collect',
      },
      {
        title: 'Charger flottes masse',
        url: '/chargement-flotte-masse',
      },
    ]
  },


  {
    title: 'Gestion des dotations',
    icon: 'ft-sidebar',
    url: '/gestion-dotations',     
    childrens: [
      // {
      //   title: 'Dotations Globales',
      //   url: '/dotationmensuel-clientes',
      // },
     {
        title: 'Dotation mensuelles globales',
        url: '/dotationmensuel-global',
      },
      {
        title: 'Dotation Clientes',
        url: '/dotationponctuel-clientes',
      }
      /*{
        title: 'Dotation ponctuelle globale',
        url: '/dotationponctuel-global',
      },*/  
    ]
  },

  {
    title: 'Comptabilité',
    icon: 'ft-archive',
    url: '/comptabilite',
    childrens: [
      {
        title: 'Mes commmandes',
        url: '/mes-commandes',
      },
      {
        title: 'Mes factures',
        url: '/mes-factures',
      },
      {
        title: 'Releve de compte',
        url: '/releve-compte',
      },
      {
        title: 'Mes paiements ',
        url: '/mes-paiements',
      },
    ]
  },

  {
    title: 'Paramétres',
    icon: 'ft-settings',
    url:'/settings',
    childrens: [
      {
        title: 'Gestion flottes ',
        url: '/gestion-flotte',
      },
      {
        title: 'Utilisateurs ',
        url: '/creation-utilisateur',
      },

    ]
  },

]

export const NAVIGATION_UTILISATEUR : any[] = [
  {
      title: 'Tableau de bord',
      icon: 'ft-home',
      url: '/dashboard-user',
    },
    {
      heading: true,
      title: 'Services'
    },
    {
      title: 'Credit d\'appel',
      icon: 'ft-phone-call',
      url: '/credit-appel',
    },
    {
      title: ' Service SMS',
      icon: 'ft-mail',
      url: '/service-sms',
      childrens: [
        {
          title: 'Groupes de contacts ',
          url: '/groupe-contact',
        },
        {
          title: 'Contacts SMS',
          url: '/contact',
        },
       
        {
          title: 'Envois SMS',
          url: '/envoyer-sms',
        },
       
      ]
    },

  ]
  



