import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AgmCoreModule } from '@agm/core';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { SplashScreenService } from './core/services/splash-screen.service';
import { AppComponent } from './app.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BackdropComponent } from './core/components/backdrop/backdrop.component';
import { ThemeConfigService } from './core/services/theme-config.service';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthGuardService } from './core/services/auth-guard.service';
import {ConfigModule} from 'ngx-envconfig';
import {GlobalHttpInterceptorService} from './core/services/global-http-interceptor.service';
import {GlobalErrorHandlerService} from './core/services/global-error-handler.service';
import { JwtHelperService, JwtModule, JwtModuleOptions, JWT_OPTIONS } from '@auth0/angular-jwt';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module


/*
message.text = 'Authentification en cours';
const VALUE_MESSAGE = message.text;
import { Loading } from 'src/app/core/services/loadingText';
import { DetailsOfaComponent } from './content/pages/mes-ofa/details-ofa/details-ofa.component';
let sampleVar: Loading;
sampleVar.value = 'value';
*/
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
};
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsSize: 60,
};

@NgModule({
  declarations: [
    AppComponent,
    BackdropComponent,
    ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgxSpinnerModule,
    LoadingBarModule.forRoot(),
    TranslateModule.forRoot(),
    ToastrModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    ConfigModule.forRoot({ state: 'development' }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    Ng2SearchPipeModule,
    FormsModule,
    NgxPaginationModule
  ],
  providers: [
    ThemeConfigService,
    SplashScreenService,
    AuthGuardService,
      { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
      JwtHelperService,
      {
          provide: PERFECT_SCROLLBAR_CONFIG,
          useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      },
      { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
      { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
