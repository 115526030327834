
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgbDropdownModule, NgbModalModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { HeaderComponent } from './header.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { FormsModule } from '@angular/forms';
import { AppService } from 'src/app/core/services/app.service';
import {EventEmitterService} from '../../../../core/services/event-emitter.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NgbDropdownModule,
    NgbModalModule,
    PerfectScrollbarModule,
    FormsModule,
    SweetAlert2Module.forRoot(),
  ],
  declarations: [HeaderComponent],
  providers: [AuthenticationService, LoadingBarService, NgbActiveModal, AppService, EventEmitterService],
  exports: [HeaderComponent]
})
export class HeaderModule { }
