import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from './core/services/translation-loader.service';
import { SplashScreenService } from './core/services/splash-screen.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { BackdropService } from 'src/app/core/services/backdrop.service';
import { locale as enLang } from './config/i18n/en';
import { locale as ruLang } from './config/i18n/ru';
import { locale as frLang } from './config/i18n/fr';
import { AuthenticationService } from './core/services/authentication.service';
import { environment } from 'src/environments/environment';
import {AppService} from './core/services/app.service';
import {ConfigService} from 'ngx-envconfig';
import { LocalStorageService } from 'ngx-webstorage';
import { EventEmitterService } from './core/services/event-emitter.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

    
    loaderText: any;
    logger: any;
    intervalDirection = 0;
    intervalStructure = 0;
    constructor(
        private _router: Router,
        private _service: AppService,
        private configService: ConfigService,
        private storage: LocalStorageService,
        private loader: LoadingBarService,
        private _splashScreenService: SplashScreenService,
        private _translateService: TranslateService,
        private eventEmitter: EventEmitterService,
        private _translationLoaderService: TranslationLoaderService,
        private _backdropService: BackdropService,
        private _auth: AuthenticationService) {
        // Set translations
        this._translateService.addLangs(['en', 'ru', 'fr']);
        this._translationLoaderService.loadTranslations(enLang, ruLang, frLang);
        this._translateService.setDefaultLang('ru');
        this._translationLoaderService.setInitLang();
    }

    ngOnInit()
    {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                // set page progress bar loading to start on NavigationStart event router
                this.loader.start();
            }
            if (event instanceof RouteConfigLoadStart) {
                this.loader.increment(35);
            }
            if (event instanceof RouteConfigLoadEnd) {
                this.loader.increment(75);
            }
            if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                this._backdropService.hide();
                // set page progress bar loading to end on NavigationEnd event router
                this.loader.complete();
            }
        });
    }

    ngAfterViewInit(): void {
        this._splashScreenService.init();
    }


}
