import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './content/layout/layout.component';
import { LayoutModule } from './content/layout/layout.module';
import { AuthGuardService as AuthGuard } from './core/services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: '', redirectTo: '/login', pathMatch: 'full'},
      {
          path: 'dashboard',
          loadChildren: './content/pages/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'dashboard-user',
        loadChildren: './content/pages/dashboard-user/dashboard-user.module#DashboardUserModule',
    },
      {
        path: 'credit-appel',
        loadChildren: './content/pages/credit-appel/credit-appel.module#CreditAppelModule',
      },
      {
        path: 'service-sms',
        loadChildren: './content/pages/service-sms/service-sms.module#ServiceSmsModule',
      },
      {
        path: 'charger-flotte',
        loadChildren: './content/pages/charger-flottes/charger-flottes.module#ChargerFlottesModule',
      },

      {
        path: 'gestion-dotations',
        loadChildren: './content/pages/gestion-dotations/gestion-dotations.module#GestionDotationsModule',
      },

      {
        path: 'comptabilite',
        loadChildren: './content/pages/comptabilite/comptabilite.module#ComptabiliteModule',
      },

      {
        path: 'internet-mobile',
        loadChildren: './content/pages/internet-mobile/internet-mobile.module#internetmobileModule',
      },

      {
        path: 'mobile-money',
        loadChildren: './content/pages/mobile-money/mobile-money.module#mobilemoneyModule',
      },

      {
        path: 'sms-pro',
        loadChildren: './content/pages/sms-pro/sms-pro.module#smsproModule',
      },

      {
        path: 'historique-transactions',
        loadChildren: './content/pages/historique-transactions/historique-transactions.module#historiquetransactionsModule',
      },

      {
        path: 'historique-activation',
        loadChildren: './content/pages/historique-activation/historique-activation.module#historiqueactivationModule',
      },

      {
        path: 'reporting',
        loadChildren: './content/pages/reporting/reporting.module#ReportingModule',
      },
      {
        path: 'settings',
        loadChildren: './content/pages/parametres/parametres.module#ParametresModule',
      },

      {
        path: 'offre-services',
        loadChildren: './content/pages/offre-services/offre-services.module#OffreServicesModule',
      },
      {
        path: 'dotations',
        loadChildren: './content/pages/dotations/dotations.module#DotationsModule',
      },
      {
        path: 'mes-flottes',
        loadChildren: './content/pages/mes-flottes/mes-flottes.module#MesFlottesModule',
      },
      {
        path: 'mes-rechargements',
        loadChildren: './content/pages/rechargement/rechargement.module#RechargementModule',
      },
      {
        path: 'mobile-moneyuser',
        loadChildren: './content/pages/mobile-moneyuser/mobile-moneyuser.module#MobileMoneyUserModule',
      },
      {
        path: 'mes-beneficiaires',
        loadChildren: './content/pages/mes-beneficiaires/mes-beneficiaires.module#MesBeneficiairesModule',
      }
    ]
  },
    {
        path: 'error-500',
        loadChildren: './content/pages/error-500/error-500.module#Error500Module',
    },
    {
      path: 'login',
      loadChildren: './content/pages/login/login.module#LoginModule',
  },

  {
    path: 'motpasse-oublie',
    loadChildren: './content/pages/motpasse-oublie/motpasse-oublie.module#MotpasseOublieModule',
}
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    LayoutModule,
  ],
  declarations: [],
  exports: [],
})
export class AppRoutingModule { }
