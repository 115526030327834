import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { configuration } from 'src/app/configuration';
import { Observable } from 'rxjs';
import { GlobalVariableService } from './global-variable.service';

const API_URL = configuration.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class AuthenticationService {
    matricule: any;
    constructor(private http: HttpClient, private variable: GlobalVariableService) {
        console.log('Authentication Service Provider running');
    }

    loginUser(data): Observable<any> {
        return this.http.post<any>(API_URL + 'login', data, httpOptions);
    }

    loggedIn() {
        return !!sessionStorage.getItem('matricule');
    }

    logoutUser() {
       this.http.get<any>(API_URL + 'user/logout');
    }

    changePasswordUser(userEmail) {
        return this.http.post<any>(API_URL + 'reset_password' , userEmail);
    }
}

