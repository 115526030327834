import { Component, OnInit } from '@angular/core';

import { ThemeConfigService } from 'src/app/core/services/theme-config.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Router} from '@angular/router';
import swal from "sweetalert2";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  response: any;
    constructor(
    public themeConfig: ThemeConfigService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

}
