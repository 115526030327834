import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationLoaderService } from 'src/app/core/services/translation-loader.service';
import { ComponenRegistryService } from 'src/app/core/services/component-registry.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppService } from 'src/app/core/services/app.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { GlobalVariableService } from '../../../../core/services/global-variable.service';
import { LocalStorageService } from 'ngx-webstorage';
import {EventEmitterService} from '../../../../core/services/event-emitter.service';
import {ConfigService} from 'ngx-envconfig';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    sideBar: any;
    quickSidebar: any;
    private _unsubscribeAll: Subject<any>;
    nameUserConnected: string;
    emailUserConnected: string;
    listCampagne: any;
    listCampagneOfa: any;
    old_password: string;
    new_password: string;
    c_new_password: string;
    response: any;
    typeView: string;
    PORTAIL_NAME: string;
    API_URL: any;
    stateView: any;
    user: any;
    ancienpassword:any;
    newpassword:any;
    password:any;
    ENVIRONNEMENT: any;
    constructor(
        public _translationLoaderService: TranslationLoaderService,
        private _componenRegistryService: ComponenRegistryService,
        private _auth: AuthenticationService,
        private _http: HttpClient,
        public _toastr: ToastrService,
        private _loader: LoadingBarService,
        private _router: Router,
        private _app: AppService,
        private storage: LocalStorageService,
        private _service: AppService, private activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private variable: GlobalVariableService,
        private configService: ConfigService,
        private eventEmitter: EventEmitterService
    ) {
        this._unsubscribeAll = new Subject();
        this.API_URL = configService.get('HOST_API_ONLINE');
        this.ENVIRONNEMENT = configService.get('HOST_ENVIRONMENT');
    }
  ngOnInit() {
    this.user = this.variable.getUser();
   //console.log(this.user);
        this._componenRegistryService.onRegistryChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(registry => {
                this.sideBar = this._componenRegistryService.getComponent('sidenav');
                this.quickSidebar = this._componenRegistryService.getComponent('quick-sidebar');
            });

            // // get UserData
            // this.user = JSON.parse(this.variable.getUser());
            // this.nameUserConnected = this.user.nom + ' ' + this.user.prenoms;
            // this.emailUserConnected = this.user.email;
    }


    ngAfterViewInit() {}

    toggleSidebar(event): void {
        event.preventDefault();
        this.sideBar.sidebarToggleHandler();
    }

    toggleQuickSidebar(event): void {
        event.preventDefault();
        this.quickSidebar.toggleOpen();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    editPassword(content) {
        this.activeModal = this.modalService.
        open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true  });
    }

    changePassword(user) {
        this._loader.start();
        const data = {
          email: user.email,
          old_password: this.ancienpassword,
          password : this.password,
          user_id: this.user.id ,   

        }
        this._service.putData('login/update_user_pass', data)
        .subscribe(
          res => {
    if (!res.error) {
              this._toastr.success('succes',res.message);
  this.activeModal.close();

    } else {
      this._toastr.error('error',res.message);
    }
  //  this._loader.stop();

  },
  
  (err) => {
    //  console.log(err);
   // this._loader.stop();
  }
);
}

    logout() {
        this._loader.start();
        this._router.navigate (['/login']);
        this._loader.stop();
    }

}
