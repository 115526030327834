import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {GlobalVariableService} from './global-variable.service';
import {ConfigService} from 'ngx-envconfig';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'developpment/json' }),
};

@Injectable()
export class AppService {
  API_URL: any;
  FILE_URL: any;
  constructor(private http: HttpClient, private _variable: GlobalVariableService, public configService: ConfigService) {
      this.FILE_URL = configService.get('FILE_URL');
      console.log('App Service Provider running');
      this.API_URL = configService.get('HOST_API');
  }

  getdash(url, data) {
    return this.http.get<any>(this.API_URL + url, data,);
  }

  postData(url, data): Observable<any> {
    return this.http.post<any>(this.API_URL + url, data,  {
      headers: new HttpHeaders({
        'Content-Type': 'developpment/json',
   
      }),
    });
  }

  putData(url, data): Observable<any> {
    return this.http.put<any>(this.API_URL + url, data,  {
      headers: new HttpHeaders({
        'Content-Type': 'developpment/json',
   
      }),
    });
  }
 

  updateData(url, data): Observable<any> {
    return this.http.post<any>(this.API_URL + url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  put(url, data): Observable<any> {
    return this.http.put<any>(this.API_URL + url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }
  getData(url): Observable<any> {
    return this.http.get<any>(this.API_URL + url, {
      headers: new HttpHeaders({
        'Content-Type': 'developpment/json',
      }),
    });
  }

  getfile(url): Observable<any> {
    return this.http.get<any>(this.FILE_URL + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this._variable.getAccessToken(),
      }),
    });
  }

  deleteData(url): Observable<any> {
    return this.http.delete<any>(this.API_URL + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  getFicheInit(idCampagne): Observable<any> {
    return this.http.get<any>(this.API_URL + 'fiche/init/campagne/' + idCampagne);
  }
}
