import { Injectable } from '@angular/core';
import { NAVIGATION_ADMIN, NAVIGATION_UTILISATEUR } from './navigation-data';
import { GlobalVariableService } from '../services/global-variable.service';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    menu: any = [];
    constructor(private variable: GlobalVariableService, private storage: LocalStorageService) { }
    

    getNavigation() {
        const user = JSON.parse(localStorage.getItem('user'));
        console.log('---------------');
        console.log(user);
        
        


        let profil = user.profil;
        console.log('---------------');
        console.log(profil);

       
        if (profil == 'Gestionnaire') {
            return NAVIGATION_ADMIN || [];
        } else if (profil == 'Utilisateur') {
            return NAVIGATION_UTILISATEUR || [];
        }
    }
}
