import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalVariableService {
  public menus_id: any;
  public menu: any;
  public permissions: any;
  constructor() {
    this.menus_id = '';
    this.menu = [];
    this.permissions = [];
  }

    setUser(data) {
        window.localStorage.setItem('user', JSON.stringify(data));
    }

    setTitleAction(data) {
      window.localStorage.setItem('title', data);
    }

    setDirection(data) {
      window.localStorage.setItem('direction', data);
    }

    setIdDirection(data) {
      window.localStorage.setItem('idDirection', data);
    }

    setZone(data) {
      window.localStorage.setItem('zone', data);
    }

    setIdZone(data) {
      window.localStorage.setItem('idZone', data);
    }

    setIdTournee(data) {
      window.localStorage.setItem('idTournee', data);
    }

    setTournee(data) {
      window.localStorage.setItem('tournee', data);
    }

    setExploitation(data) {
      window.localStorage.setItem('exploitation', data);
    }

    setIdExploitation(data) {
      window.localStorage.setItem('idExploitation', data);
    }

    setPosteName(data) {
      window.localStorage.setItem('posteName', data);
    }

    setPosteData(data) {
      window.localStorage.setItem('posteData', JSON.stringify(data));
    }

    setMetriqueData(data) {
      window.localStorage.setItem('metriqueData', JSON.stringify(data));
    }

    setAccessToken(value) {
      window.localStorage.setItem('access_token', value);
    }

    setErrorMessage(data) {
      window.localStorage.setItem('message', data);
    }

    setMenu(value) {
      localStorage.setItem('menu', JSON.stringify(value));
    }
  
    setMenuId(value) {
      this.menus_id = value;
    }

    setPermissions(value) {
    localStorage.setItem('permissions', JSON.stringify(value));
    }

    getUser() {
      return JSON.parse(window.localStorage.getItem('user'));
    }

    getIdDirection() {
      return window.localStorage.getItem('idDirection');
    }

    getIdZone() {
      return window.localStorage.getItem('idZone');
    }

    getIdExploitation() {
      return window.localStorage.getItem('idExploitation');
    }

    getIdTournee() {
      return window.localStorage.getItem('idTournee');
    }

    getTitleAction() {
      return window.localStorage.getItem('title');
    }

    getDirection() {
      return window.localStorage.getItem('direction');
    }

    getTournee() {
      return window.localStorage.getItem('tournee');
    }

    getZone() {
      return window.localStorage.getItem('zone');
    }

    getExploitation() {
      return window.localStorage.getItem('exploitation');
    }

    getPosteName() {
      return window.localStorage.getItem('posteName');
    }

    getPosteData() {
      return window.localStorage.getItem('posteData');
    }

    getMetriqueData() {
      return window.localStorage.getItem('metriqueData');
    }

    getErrorMessage() {
      return window.localStorage.getItem('message');
    }

    getAccessToken() {
      return window.localStorage.getItem('access_token');
    }
  
    getMenuId() {
      return this.menus_id;
    }

    getMenu() {
      // return this.menu;
      return JSON.parse(localStorage.getItem('menu'));
    }

    getPermissions() {
      return JSON.parse(localStorage.getItem('permissions'));
    }

    removeUser() {
      localStorage.removeItem('user');
    }

    removePosteData() {
      localStorage.removeItem('posteData');
    }

}
