import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GlobalVariableService } from "./global-variable.service";
 
@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
 
  constructor(public router: Router, public variable: GlobalVariableService) {
  }
 
  //1.  No Errors
  intercept1(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        window.location.href = "/error";
        return throwError(error.message);
      })
    )
  }
 

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.variable.getAccessToken();
    console.log(token);
    req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    return next.handle(req).pipe(
      catchError((error) => {
        let handled: boolean = false;
        console.error(error);
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:  
              // this.router.navigate(['/lockscreen'])
              handled = true;
                break;
              case 403: 
              window.location.href = "/error-403";
              handled = true;
                break;
                case 404:
                    // window.location.href = "/error-404";
                    handled = true;
                    break;
              case 500:
                alert('error 500')
                handled = true;
                break;
            }
          }
        }
        else {
          console.error("Other Errors");
        }
        if (handled) {
          console.log('return back ');
          return of(error);
        } else {
          console.log('throw error back to to the subscriber');
          return throwError(error);
        }
 
      })
    )
  }
}
