import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class EventEmitterService {
    invokeGetAllPropositions = new EventEmitter();
    invokeGetAllArbitrage = new EventEmitter();
    invokeGetSolde = new EventEmitter();
    invokeGetAnalyse = new EventEmitter();
    invokeGetTenuePosteData = new EventEmitter();
    invokeCallDownloadFileDirection = new EventEmitter();
    invokeCallDownloadFileStructure = new EventEmitter();
    subsVar: Subscription;
    constructor() { }

    onGetAllPropositions() {
        this.invokeGetAllPropositions.emit();
    }

    onGetAllArbitrage() {
        this.invokeGetAllArbitrage.emit();
    }

    onGetAllAnalyse() {
        this.invokeGetAnalyse.emit();
    }

    onGetSolde() {
        this.invokeGetSolde.emit();
    }

    onGetTenuePosteData() {
        this.invokeGetTenuePosteData.emit();
    }

    onGetFileDirection() {
        this.invokeCallDownloadFileDirection.emit();
    }

    onGetFileStructure() {
        this.invokeCallDownloadFileStructure.emit();
    }

}